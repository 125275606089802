import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import {
  HeroBlock,
  FeaturedItemBlock,
  Testimonial,
  Decoration,
  Space,
  SEO,
} from '../components/shared'
import { CollectionSlider, ProductSlider } from '../components/shared/sliders'
import { mobileVw, desktopBreakpoint, desktopVw, colors } from '../styles'
import { useLocale } from '../hooks'

const EBoutique = ({
  location,
  data: { contentfulPageEBoutique, contentfulGlobalSite },
  pageContext: { shopenful },
}) => {
  const {
    heroblock,
    collectionSlider,
    productSlider,
    decorations,
    seoDescription,
    seoTitle,
  } = contentfulPageEBoutique

  const {
    classicProductPageTestimonialDecoration,
    currentSeason,
  } = contentfulGlobalSite
  const locale = useLocale()

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
    script.async = true
    if (locale === 'fr') {
      setTimeout(() => document.body.appendChild(script), 1000)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])
  return (
    <EboutiquePageWrapper>
      <SEO
        description={seoDescription}
        location={location}
        title={seoTitle}
        pageType='e-boutique'
      />
      <HeroBlock gold data={heroblock} />
      <Wrapper>
        {
          collectionSlider &&
          <CollectionSlider
            titleHeading={collectionSlider.title.concat(
              'Exclusivités en ligne & Boutiques Maille',
            )}
            slides={collectionSlider.slides}
            button='Achetez en ligne'
            buttonSlug='/e-boutique'
            productCategory
          />
        }
      </Wrapper>
      <Space l={3} s={3} />
      {
        productSlider &&
        <ProductSlider
          button={productSlider.button}
          title={productSlider.title}
          products={productSlider.products}
          buttonSlug='/e-boutique/cadeaux/'
          shopenful={shopenful}
          gifting={true}
        />
      }

      <Testimonial
        // defaultImage={defaultVideoTestimonial}
        decoration={classicProductPageTestimonialDecoration}
      />
      <Space l={5} s={2} />
      <StyledFeaturedItemBlock>
        <FeaturedItemBlock
          title={['La recette', 'du mois']}
          id={100}
          season={currentSeason}
          hideRating
        />
      </StyledFeaturedItemBlock>
      <Space l={13} s={16} />
      <StyledDecoration>
        {decorations.map((data, i) => {
          return <Decoration key={i} data={data} />
        })}
      </StyledDecoration>
    </EboutiquePageWrapper>
  )
}

const EboutiquePageWrapper = styled.div`
  overflow-x: hidden;
`

const StyledFeaturedItemBlock = styled.div`
  margin: 0 ${mobileVw(16)};
  position: relative;
  z-index: 1;

  h2 {
    span {
      display: inline-block;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(260)} ${desktopVw(-50)};
    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }
  }
`

const StyledDecoration = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  h2 {
    span {
      :nth-child(3) {
        color: ${colors.mailleGold};
        font-size: ${mobileVw(20)};
        display: block;
      }
    }
  }
  .cta_button {
    display: none;
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(-80)};

    h2 {
      span {
        :nth-child(3) {
          font-size: ${desktopVw(25)};
        }
      }
    }
  }
`

export const EboutiqueQuery = graphql`
  query EboutiqueQuery($id: String!, $node_locale: String!) {
    contentfulPageEBoutique(id: { eq: $id }) {
      seoDescription
      seoTitle
      decorations {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      heroblock {
        textSlider {
          title
          text {
            text
          }
          link {
            link {
              ...linkTarget
            }
          }
          description {
            raw
          }
        }
        video {
          name
          poster {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        marginDescription
        title
        marginBottom
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      collectionSlider {
        title
        slides {
          ... on ContentfulPageEBoutiqueCollection {
            title
            slug
            thumbnailImage {
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              title
            }
          }
        }
      }
      productSlider {
        button
        title
        products {
          thumbnailLabel
          promotion
          shopifyProduct {
            handle
            id
            variants {
              edges {
                node {
                  id
                  title
                  price{
                    amount
                  }
                }
              }
            }
          }
          thumbnailImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
          images {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      currentSeason
      classicProductPageTestimonialDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      defaultVideoTestimonial {
        name
        poster {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        videoMov {
          file {
            url
          }
        }
        videoMp4 {
          file {
            url
          }
        }
      }
    }
  }
`

export default EBoutique
